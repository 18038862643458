import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { TooltipOptions } from 'weavix-shared/models/tooltip.model';
import { sleep } from 'weavix-shared/utils/sleep';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { ChipListService } from './chip-list.service';

export interface Chip {
    id?: string;
    name?: string;
    hidden?: boolean;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-chip-list',
    templateUrl: './chip-list.component.html',
    styleUrls: ['./chip-list.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipListComponent),
            multi: true,
        },
    ],
})
export class ChipListComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
    @Input() inputId: string = '';
    @Input() placeholder: string = '';
    @Input() translate: boolean = true;
    @Input() label: string = '';
    @Input() showLabel: boolean = true;
    @Input() floatLabel: boolean = true;
    @Input() showErrors: boolean = true;
    @Input() dataSource: Chip[] = [];
    @Input() hiddenChoices: string[] = [];
    @Input() emptyOptionText: string = '';
    @Input() displayKey: string = 'name';
    @Input() required: boolean = false;
    @Input() tooltip: TooltipOptions;
    @Input() removeSelected: boolean = true;
    @Input() canAdd: boolean = false;
    @Input() addNewItemLabel: string;
    @Input() addNewItemFn: (value: string) => Promise<any>;
    @Input() dark: boolean = false;
    @Input() container: HTMLElement;
    @Input() isDisabled: boolean = false;
    @Output() valueChanged: EventEmitter<string[]> = new EventEmitter();
    @Output() inputValueChange: EventEmitter<string> = new EventEmitter();

    @ViewChild('input') textInput: ElementRef;
    @ViewChild(MatAutocompleteTrigger, { static: true }) chipAuto: MatAutocompleteTrigger;

    selectedIds: string[] = [];
    filteredItems: Observable<Chip[]>;
    selectedItems: Chip[] = [];
    validItems: Chip[] = [];

    formControl: FormControl;
    inputCtrl: FormControl = new FormControl();

    hasMoreOptions: boolean;

    currentFilterSearchValue: string;

    constructor(
        private chipListService: ChipListService,
        private injector: Injector,
    ) {
        Utils.safeSubscribe(this, this.chipListService.newItem$)
            .subscribe(newItem => this.validItems = Utils.sortAlphabetical(this.dataSource.slice(), this.displayKey));
    }

    async ngOnInit() {
        this.formControl = (await this.injector.get(NgControl)).control as FormControl;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!(Object.keys(changes).length === 1 && 'hiddenChoices' in changes)) {
            this.setValidItems();
        }
        this.setupChipControl();
    }

    ngAfterViewInit(): void {
        this.updateOptionsWhenScrolled();
    }

    private updateOptionsWhenScrolled(): void {
        window.addEventListener('scroll', () => {
            this.chipAuto?.updatePosition();
            if (this.container) {
                const containerBounds = this.container.getBoundingClientRect();
                const textInputBounds = this.textInput.nativeElement.getBoundingClientRect();
                const textInputIsNotVisible = (): boolean => (textInputBounds.top + textInputBounds.height) - containerBounds.top > containerBounds.height ||
                textInputBounds.bottom - (textInputBounds.height / 2) < containerBounds.top;
                if (textInputIsNotVisible()) {
                    this.chipAuto?.closePanel();
                    this.textInput.nativeElement.blur();
                }
            }
        }, true);
    }

    setValidItems() {
        this.validItems = Utils.sortAlphabetical(this.dataSource.slice(), this.displayKey);
        this.selectedItems = [];
        this.selectedIds.forEach(id => {
            const itemIndex = this.validItems.findIndex(i => i.id === id);
            if (itemIndex > -1) {
                this.selectedItems.push(cloneDeep(this.validItems[itemIndex]));
                if (this.removeSelected) this.validItems.splice(itemIndex, 1);
            }
        });
    }

    setupChipControl(): void {
        const filterItems = (search: string) => {
            this.inputValueChange.emit(search);
            this.currentFilterSearchValue = search ? search.toLowerCase() : '';
            return this.validItems.filter(t => t[this.displayKey].toLowerCase().includes(this.currentFilterSearchValue) && !this.hiddenChoices.some(c => c === t.id)).slice(0, Utils.SELECT_LIMIT);
        };

        this.filteredItems = this.inputCtrl.valueChanges.pipe(
            startWith<string | Chip>(''),
            map(value => typeof value === 'string' ? value : (value ? value[this.displayKey] : '')),
            map(item => item ? filterItems(item) : this.validItems.filter(i => !this.hiddenChoices.some(c => c === i.id)).slice(0, Utils.SELECT_LIMIT)),
        );

        if (this.currentFilterSearchValue) filterItems(this.currentFilterSearchValue);

        this.hasMoreOptions = this.validItems.length > Utils.SELECT_LIMIT;
    }

    itemIsSelected(item: Chip): boolean {
        return this.selectedItems.some(s => s.id === item.id);
    }

    displayFn(item?: Chip): string | undefined {
        return item ? item[this.displayKey] : undefined;
    }

    async remove(item: Chip) {
        this.selectedItems.splice(this.selectedItems.findIndex(t => t.id === item.id), 1);
        if (this.removeSelected) this.validItems.push(cloneDeep(this.dataSource.find(i => i.id === item.id)));
        Utils.sortAlphabetical(this.validItems, x => x.name);
        this.setupChipControl();
        this.onChange(this.selectedItems.map(t => t.id));
        this.formControl.markAsTouched();
        this.valueChanged.next(this.selectedItems.map(t => t.id));

        // reposition chip list
        if (this.chipAuto.panelOpen) {
            this.chipAuto.closePanel();
            this.chipAuto.openPanel();
        }
    }

    async addItem(event: MatAutocompleteSelectedEvent) {
        const id = event.option.value.id;
        await this.handleAddItem(id);
    }

    async addNewItem(value) {
        if (this.addNewItemFn) {
            this.addNewItemFn.call(this, value).then(
                async newItem => {
                    if (newItem) {
                        this.dataSource.push(newItem);
                        this.chipListService.onNewItem(newItem);
                    }
                    await this.handleAddItem(newItem?.id);
                },
            );
        }
    }

    private async handleAddItem(id: string) {
        if (id) {
            const index = this.validItems.findIndex(item => item.id === id);
            this.selectedItems.push(this.validItems[index]);
            if (this.removeSelected) this.validItems.splice(index, 1);
            this.setupChipControl();
            this.onChange(this.selectedItems.map(t => t.id));
            this.valueChanged.next(this.selectedItems.map(t => t.id));
        }
        this.inputCtrl.setValue('');
        await sleep(1);
        this.chipAuto.openPanel();
        this.textInput.nativeElement.value = '';
        this.textInput.nativeElement.focus();
    }

    // Form control interface fns
    onChange = (_: string[]) => {};

    onTouched = () => {};

    async writeValue(ids: string[] = []) {
        if (!ids) return;
        this.selectedIds = ids;
        this.setValidItems();
        this.setupChipControl();
    }

    registerOnChange(fn: (ids: string[]) => void) { this.onChange = fn; }

    registerOnTouched(fn: () => void) { this.onTouched = fn; }
    // END Form control

}
